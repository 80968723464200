import React from "react";
import AnchorButton from "../components/AnchorButton";
import Container from "../components/Container";
import Header from "../components/Header";
import Section from "../components/Section";
import { POSITIONS } from "../data";
import classes from "./Business.module.css";

const Business = () => {
  return (
    <>
      <Header>
        <h2>Experiencia Empresarial</h2>
      </Header>
      <Container>
        {POSITIONS.map(
          (
            {
              name,
              description,
              businessLogoPath,
              businessName,
              businessDescription,
              businessUrl,
              organizationUrl,
            },
            index
          ) => (
            <PositionDetail
              key={index}
              positionName={name}
              businessDescription={businessDescription}
              businessName={businessName}
              businessLogoPath={businessLogoPath}
              positionDescription={description}
              businessUrl={businessUrl}
              organizationUrl={organizationUrl}
            />
          )
        )}
      </Container>
    </>
  );
};

const PositionDetail = ({
  positionName,
  businessName,
  businessDescription,
  positionDescription,
  businessLogoPath,
  organizationUrl,
  businessUrl,
}) => {
  return (
    <Section direction="row" className={classes.position_detail}>
      <div className={classes.business_description}>
        <h2>{positionName}</h2>
        <h3>{businessName}</h3>
        <p>{businessDescription}</p>
        {/* <p>{positionDescription}</p> */}
        <br />
        <div className={classes.buttons}>
          {businessUrl && (
            <AnchorButton
              href={businessUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              CONOCER EMPRESA
            </AnchorButton>
          )}
          {organizationUrl && (
            <AnchorButton
              href={organizationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              VER ORGANIZACIÓN
            </AnchorButton>
          )}
        </div>
      </div>
      <img
        src={businessLogoPath}
        alt={`Logo de ${positionName}`}
        className={classes.business_logo}
      />
    </Section>
  );
};

export default Business;
