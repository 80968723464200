import { useNavigate } from "react-router-dom";
import Button from "./Button";
import classes from "./PageSection.module.css";
import Section from "./Section";

const PageSection = ({ imgPath, title, description, pagePath }) => {
  const navigate = useNavigate();
  return (
    <Section className={`${classes.page_description}`} direction="row">
      <img src={imgPath} alt="" className={classes.page_picture} />
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
        <Button onClick={() => navigate(pagePath)}>VER MÁS</Button>
      </div>
    </Section>
  );
};

export default PageSection;
