import React from "react";
import Container from "../components/Container";
import Header from "../components/Header";
import ImageCard from "../components/ImageCard";
import ProjectCard from "../components/ProjectCard";
import Section from "../components/Section";
import { ADVICES, INTERNSHIPS, PROJECTS, PUBLICATIONS } from "../data";

import classes from "./Innovation.module.css";

const Innovation = () => {
  return (
    <>
      <Header>
        <h2>Innovación e Investigación</h2>
      </Header>
      <Container>
        <Section>
          <h2>Proyectos</h2>
          <div className="cards_container">
            {PROJECTS.map(({ name, year, conditions, group }, index) => (
              <ProjectCard
                key={index}
                name={name}
                year={year}
                conditions={conditions}
                group={group}
              />
            ))}
          </div>
        </Section>
        <Section>
          <h2>Pasantias</h2>
          <div className={classes.cards_container}>
            {INTERNSHIPS.map(
              ({ imgPath, reason, year, entity, country }, index) => (
                <ImageCard
                  key={index}
                  title={`${reason} (${year})`}
                  imgPath={imgPath}
                >
                  <span>{`${entity} (${country})`}</span>
                </ImageCard>
              )
            )}
          </div>
        </Section>
        <Section>
          <h2>Publicaciones</h2>
          <br />
          Publicaciones en revistas:
          <br />
          <ul>
            {PUBLICATIONS.map(({ title, year, magazine, link }, index) => (
              <li key={index}>
                <p>
                  <b>
                    {title} ({year})
                  </b>
                  <br />
                  {magazine}{" "}
                  <a className={classes.link} href={link}>
                    (ver publicación)
                  </a>
                </p>
              </li>
            ))}
          </ul>
        </Section>
        <Section>
          <h2>Asesorías</h2>
          <table className={classes.advices_table}>
            <thead>
              <tr>
                <th>Universidad</th>
                <th>Tesis</th>
                <th>Tesista(a)</th>
                <th>Fecha de Aceptación de Tesis</th>
              </tr>
            </thead>
            <tbody>
              {ADVICES.map(({ university, degree, date, student }, index) => (
                <tr key={index}>
                  <td>{university}</td>
                  <td>{degree}</td>
                  <td>{student}</td>
                  <td>{date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Section>
      </Container>
    </>
  );
};

export default Innovation;
