import React from "react";
import classes from "./Section.module.css";

const Section = ({ children, className, direction = "column" }) => {
  return (
    <div
      className={`${classes.section} ${className}`}
      style={{ flexDirection: direction }}
    >
      {children}
    </div>
  );
};

export default Section;
