import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Innovation from "./routes/Innovation";
import Business from "./routes/Business";
import Teaching from "./routes/Teaching";
import Home from "./routes/Home";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import ScrollToTop from "./components/ScrollToTop";

library.add(far, fab, faEnvelope);

function App() {
  return (
    <Layout>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/empresa" element={<Business />} />
          <Route path="/innovacion" element={<Innovation />} />
          <Route path="/docencia" element={<Teaching />} />
        </Routes>
      </ScrollToTop>
    </Layout>
  );
}

export default App;
