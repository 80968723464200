import React from "react";
import classes from "./Footer.module.css";
import logo from "./../logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FACEBOOK_PROFILE, LINKEDIN_PROFILE, TWITTER_PROFILE } from "../data";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <img src={logo} alt="Gladys Maquera Logo" />
      <span>&copy; Copyright 2022. Todos los derechos reservados</span>
      <div className={classes.social_links}>
        <a href={FACEBOOK_PROFILE}>
          <FontAwesomeIcon icon={["fab", "facebook-square"]} />
        </a>
        <a href={TWITTER_PROFILE}>
          <FontAwesomeIcon icon={["fab", "twitter-square"]} />
        </a>
        <a href={LINKEDIN_PROFILE}>
          <FontAwesomeIcon icon={["fab", "linkedin"]} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
