import React from "react";
import classes from "./ProjectCard.module.css";

const ProjectCard = ({ name, year, conditions, group }) => {
  return (
    <div className={`${classes.card}`}>
      <div className={classes.body}>
        <b>
          {year} - {name}
        </b>
        <hr></hr>
        <span>Condición:</span>
        <ul>
          {conditions.map((condition, index) => (
            <li key={index}>{condition}</li>
          ))}
        </ul>
        <span>Grupo:</span>
        <ul>
          {group.map((memberName, index) => (
            <li key={index}>{memberName}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProjectCard;
