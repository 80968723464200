import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, NavLink, useNavigationType } from "react-router-dom";
import logo from "../logo.svg";
import AnchorButton from "./AnchorButton";
import classes from "./MainNavigation.module.css";

const MainNavigation = () => {
  return (
    <header className={classes.header}>
      <Link to="/">
        <img src={logo} alt="Gladys Maquera Logo" />
      </Link>
      <nav>
        <ul className={classes.nav__links}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              INICIO
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/innovacion"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              INNOVACIÓN
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/empresa"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              EMPRESA
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/docencia"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              DOCENCIA
            </NavLink>
          </li>
        </ul>
      </nav>
      <AnchorButton href={"mailto:contact.gladysmaquera@gmail.com"}>
        CONTACTAR <FontAwesomeIcon icon={["far", "envelope"]} />
      </AnchorButton>
    </header>
  );
};

export default MainNavigation;
