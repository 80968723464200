export const COURSES = [
  {
    name: "Revisión por pares",
    year: "2021",
    institution: "Elsevier - Research Academy",
  },
  {
    name: "Gestión virtual de programas de incubación empresarial",
    year: "2020",
    institution: "Universidad Nacional Mayor de San Marcos (Perú)",
  },
  {
    name: "Taller Intensivo para el Desarrollo Acelerado de Patentes",
    year: "2019",
    institution: "CAF Banco de Desarrollo  de América Latina e Incubba",
  },
  {
    name: "Inversión Pública, marco normativo, programación multianual de inversiones y elaboración del PMI",
    year: "2019",
    institution: "Universidad Nacional del Callao (Perú)",
  },
  {
    name: "Turismo Rural Comunitario + La implementación de Big Data para su optimización",
    year: "2018",
    institution:
      "Universidad Peruana Unión. Organizado por Embajada de Israel, MASHAV, MCTC y el CONCYTEC",
  },
  {
    name: "Turismo rural",
    year: "2017",
    institution: "Mashav Carmel Training Center (Israel)",
  },
  {
    name: "Gestión por resultados",
    year: "2014",
    institution: "Universidad Peruana Unión y Solaris (Perú)",
  },
  {
    name: "Formulación de proyectos sociales",
    year: "2014",
    institution: "Universidad Peruana Unión (Perú)",
  },
];

export const DEGREES = [
  {
    university: { name: "Universidad Federal Fluminense", country: "Brasil" },
    degree: "Post doctorado en ingeniería de producción",
  },
  {
    university: {
      name: "Universidad Federal de Rio de Janeiro",
      country: "Brasil",
    },
    degree: "Doctorado en ciencias en ingeniería de producción",
  },
  {
    university: { name: "Universidad Estadual de Campinas", country: "Brasil" },
    degree: "Maestría en ingeniería eléctrica y de computación",
  },
  {
    university: { name: "Universidad Peruana Unión", country: "Perú" },
    degree: "Licenciada en educación matemática",
  },
  {
    university: { name: "Universidad Peruana Unión", country: "Perú" },
    degree: "Bachiller en educación",
  },
];

export const RECOGNITIONS = [
  {
    title:
      "Felicitación y Reconocimiento - I Congreso Internacional SU Andino de Ingeniería de Sistemas e Informática",
    country: "Perú",
    year: "1998",
  },
  {
    title: "Mujeres Científicas en Investigación Universitaria",
    country: "Perú",
    year: "2012",
  },
  {
    title: "Hija Predilecta del Distrito de Pilcuyo",
    country: "Perú",
    year: "2012",
  },
  {
    title: "Felicitar y Reconocer - UGEL El Collao",
    country: "Perú",
    year: "2013",
  },
  {
    title:
      "Felicitar y Reconocer - Colegio de Matemáticos del Perú Comité Ejecutivo Región Puno",
    country: "Perú",
    year: "2014",
  },
  {
    title: "Investigadora de Turismo",
    country: "Perú",
    year: "2016",
  },
  {
    title:
      'Felicitación y Reconocimiento -  Ponente en el marco del ¨Plan de fortalecimiento de capacidades e inserción de comunidades de Juli en Turismo Rural Comunitario"',
    country: "Perú",
    year: "2016",
  },
  {
    title:
      "Reconocimiento y Agradecimiento - Proyecto de la UPeU - CONCYTEC en PILCUYO",
    country: "Perú",
    year: "2018",
  },

  {
    title: "Embajador de buena voluntad de Israel ",
    country: "Israel",
    year: "2020",
  },
];

export const PROJECTS = [
  {
    name: "Plataforma Digital Inteligente y Big Data para el Turismo Rural Comunitario de la Región Puno",
    year: "2020",
    conditions: ["Investigadora Principal", "Coordinadora General"],
    group: [
      "Universidad Peruana Unión (UPeU)",
      "Universidade Federal do Rio de Janeiro (UFRJ)",
    ],
  },
  {
    name: "Proyecto de Extensionismo y Difusión Tecnológica en el Marco de la Articulación Productiva",
    year: "2016",
    conditions: ["Investigadora Principal", "Coordinadora General"],
    group: [
      "Universidad de Camerino de Italia",
      "Grupo Innop Perú EIRL",
      "Universidad Peruana Unión (UPeU)",
    ],
  },
  {
    name: "Desarrollo de Plataforma Tecnológica en la Web para la Competitividad de la Cadena Productiva en Alpacas en la Región Puno",
    year: "2013",
    conditions: ["Investigadora Principal", "Coordinadora General"],
    group: [
      "Universidad Peruana Unión (UPeU)",
      "Universidade de Camerino de Italia",
    ],
  },
  {
    name: "Búsqueda Tabú Adaptativa para el Problema de Ruteo de Vehículos con Colecta y Entrega Simultanea y Ventanas de Tiempo para una Empresa Distribuidora",
    year: "2011",
    conditions: ["Investigadora Principal", "Coordinadora General"],
    group: [
      "Universidad Peruana Unión (UPeU)",
      "Universidade Federal do Rio de Janeiro (UFRJ)",
      "Universidad Nacional Mayor de San Marcos (UNMSM)",
      "Universidad Nacional San Agustín (UNSA)",
    ],
  },
  {
    name: "Evaluación de Cuatro Variedades de Quinua (Chenopodium Quinoa Willd) para la Extracción de Germen de Proteína, Almidón y Fibra vía Molienda Húmeda",
    year: "2011",
    conditions: ["Miembro de proyecto"],
    group: ["Universidad Peruana Unión (UPeU)"],
  },
  {
    name: "Logística Inversa y Gestión Ambiental en el Estudio del Ciclo Inverso de la Cadena Productiva de Petróleo",
    year: "2009",
    conditions: ["Investigadora Principal", "Coordinadora General"],
    group: [
      "Universidad Peruana Unión (UPeU)",
      "Universidade Federal do Rio de Janeiro (UFRJ)",
      "Universidad Nacional Mayor de San Marcos (UNMSM)",
      "Universidad Nacional San Agustín (UNSA)",
    ],
  },
  {
    name: "Sistemas Logísticos y Gestión Ambiental en el Estudio del Ciclo Inverso en la Cadena del Petróleo",
    year: "2008",
    conditions: ["Miembro de proyecto"],
    group: [
      "Distribuidora BR PETROBRAS",
      "Universidade Federal do Rio de Janeiro (UFRJ)",
      "Universidade de São Paulo (USP)",
      "Universidade Federal Fluminense (UFF)",
      "Fundação Joaquin Nabuco (FUNDAJ)",
      "Universidade Federal da Paraíba (UFPB)",
      "Universidade Federal de Campina Grande (UFCG)",
    ],
  },
  {
    name: "Logística Inversa, Sistemas de Distribución: Búsqueda Dispersa Aplicada al Problema de Ruteo de Vehículos con Pedidos de Colecta y Entrega Simultánea",
    year: "2007",
    conditions: ["Investigadora Principal", "Coordinadora General"],
    group: [
      "Sociedad Brasileña de Investigación de Operaciones (SOBRAPO)",
      "Universidade Federal do Rio de Janeiro (UFRJ)",
    ],
  },
];

export const INTERNSHIPS = [
  {
    imgPath: "images/internship4.jpg",
    reason:
      "Pasantía tecnológica de pequeños productores lácteos de la sierra sur del Perú a aula de productos lácteos de la USC Lugo España",
    entity: "Universidad Santiago de Compostela",
    year: "2020",
    country: "España",
  },
  {
    imgPath: "images/internship3.jpg",
    reason:
      'Pasantía y curso en TURISMO RURAL durante la ejecución del proyecto "Plataforma Digital Inteligente y Big Data para el Turismo Rural Comunitario en la Región Puno"',
    entity:
      "Agencia Israelí  de Cooperación Internacional para el Desarrollo – MASHAV y el Centro Internacional  de Capacitación  Golda Meir MASHAV Carmel (MCTC)",
    year: "2017",
    country: "Israel",
  },
  {
    imgPath: "images/internship2.jpg",
    reason:
      "Pasantía tecnológica para mejorar la cadena de valor de microempresarios productores de fibra de alpaca de Macusani",
    entity: "Universidad de Camerino de Italia",
    year: "2017",
    country: "Italia",
  },
  {
    imgPath: "images/internship1.jpg",
    reason:
      'Ejecución del proyecto "Logística inversa en la cadena productiva de petróleo: caso Perú"',
    entity:
      "Universidad de Burgos, Facultad de Ciencias Económicas y Empresariales, Departamento de economía aplicada",
    year: "2011",
    country: "España",
  },
];

export const PUBLICATIONS = [
  {
    title:
      "Experiencias e impacto social en la aplicación de ingeniería de requisitos en el desarrollo de una plataforma inteligente",
    year: "2019",
    magazine: "Apuntes universitarios",
    link: "https://apuntesuniversitarios.upeu.edu.pe/index.php/revapuntes/article/view/358",
  },
  {
    title:
      "Logística verde e inversa, responsabilidad universitaria socioambiental corporativa y productividad",
    year: "2012",
    magazine: "Apuntes Universitarios",
    link: "#",
  },
  {
    title:
      "Scatter Search Applied to the Vehicle Routing Problem with Simultaneous Delivery and Pickup",
    year: "2011",
    magazine: "International Journal of Applied Metaheuristic Computing",
    link: "https://www.igi-global.com/gateway/chapter/69723",
  },
  {
    title:
      "Algoritmo de busca dispersa aplicado ao problema clássico de roteamento de veículos",
    year: "2007",
    magazine: "Pesquisa Operacional",
    link: "https://www.scielo.br/j/pope/a/TjQ34PqPjppqkhwPzFWcbwz/?lang=pt",
  },
  {
    title:
      "An Adaptive Tabu Search Algorithm for the Capacitated Clustering Problem",
    year: "1999",
    magazine: "International Transactions in Operational Research",
    link: "https://onlinelibrary.wiley.com/doi/10.1111/j.1475-3995.1999.tb00180.x",
  },
];

export const ADVICES = [
  {
    university: "Universidad Peruana Unión",
    degree: "Licenciado / Título",
    date: "Junio 2019",
    student: "Roger Ticona Nina",
  },
  {
    university: "Universidad Peruana Unión",
    degree: "Licenciado / Título",
    date: "Junio 2019",
    student: "Yuri Lisbeth Mamani Ramos",
  },
  {
    university: "Universidad Peruana Unión",
    degree: "Licenciado / Título",
    date: "Junio 2019",
    student: "	Yuselenin Anquise Jihuaña",
  },
  {
    university: "Universidad Peruana Unión",
    degree: "Licenciado / Título",
    date: "Junio 2019",
    student: "Rosbel Neftali Ccana Yucra",
  },
  {
    university: "Universidad Peruana Unión",
    degree: "Licenciado / Título",
    date: "Junio 2019",
    student: "Yerson Pariapaza Larico",
  },
  {
    university: "Universidad Peruana Unión",
    degree: "Licenciado / Título",
    date: "Mayo 2019",
    student: "	Félix Germán Castro Vilchez",
  },
  {
    university: "Universidad Peruana Unión",
    degree: "Licenciado / Título",
    date: "Febrero 2017",
    student: "Óscar David Mendoza Apaza",
  },
  {
    university: "Escola Politecnica da Universidade de Sao Paulo",
    degree: "Magister",
    date: "Setiembre 2010",
    student: "Antonio Celio Pereira de Mesquita",
  },
];

export const UNIVERSITIES = [
  {
    imgPath: "images/university4.jpg",
    name: "Universidad Peruana Unión",
    periods: [
      {
        period: "Octubre 2015 a la Actualidad",
        teacherType: "Ordinario-Principal",
      },
      {
        period: "Abril 2010 a Octubre 2015",
        teacherType: "Ordinario-Asociado",
      },
    ],
  },
  {
    imgPath: "images/university3.jpg",
    name: "Universidad Federal do Rio de Janeiro",
    periods: [
      {
        period: "Marzo 2006 a Julio 2006",
        teacherType: "Extraordinario(emerito, honorario y similares)",
      },
    ],
  },
  {
    imgPath: "images/university2.jpg",
    name: "Universidad Nacional del Altiplano Puno",
    periods: [
      { period: "Marzo 1997 a Diciembre 1997", teacherType: "Contratado" },
    ],
  },
  {
    imgPath: "images/university1.jpg",
    name: "Universidad Andina Nestor Caceres Velasquez",
    periods: [
      { period: "Marzo 1997 a Diciembre 1997", teacherType: "Contratado" },
    ],
  },
];

export const POSITIONS = [
  {
    name: "Presidenta de Directorio (e)",
    description: "",
    businessLogoPath: "images/business2.jpg",
    businessName: "ELECTRO PUNO S.A.A",
    businessDescription:
      "Electro Puno S.A.A., es una Empresa Concesionaria de Distribución de Energía Eléctrica en el Departamento de Puno, creada por escisión del bloque patrimonial de la Gerencia Sub Regional de Electro Sur Este S.A.A.",
    organizationUrl:
      "https://www.electropuno.com.pe/web3/index.php/conocenos/organizacion",
    businessUrl: "https://www.electropuno.com.pe/",
  },
  {
    name: "CEO",
    description: "",
    businessLogoPath: "images/business1.jpg",
    businessName: "GRUPO INNOP E.I.R.L",
    businessDescription:
      "Grupo INNOP es una empresa dedicada a la investigación, desarrollo e innovación tecnológica. También realiza consultorías y asesorías en áreas relacionadas a Ciencias de la computación.",
  },
];

export const LINKEDIN_PROFILE =
  "https://www.linkedin.com/in/gladys-maquera-2b07a0b";

export const FACEBOOK_PROFILE =
  "https://www.facebook.com/gladysmaquera.academico";

export const TWITTER_PROFILE = "https://twitter.com/GladysMaquera";

export const ACADEMIC_PROFILES = [
  {
    title: "CTI Vitae",
    description:
      "Perfil de investigador  CONCYTEC calificado registrado en CTI Vitae.",
    url: "https://ctivitae.concytec.gob.pe/appDirectorioCTI/VerDatosInvestigador.do?id_investigador=1977",
  },
  {
    title: "ORCID",
    description:
      "Perfil de investigador con idenficicador único registrado en ORCID",
    url: "https://orcid.org/0000-0001-7308-4952",
  },
  {
    title: "Google Académico",
    description:
      "Perfil de autor de publicaciones registrado en Google Académico.",
    url: "https://scholar.google.com/citations?user=pH_2a2YAAAAJ&hl=es",
  },
];
