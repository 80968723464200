import React from "react";
import Header from "../components/Header";
import Section from "../components/Section";
import { UNIVERSITIES } from "../data";
import ImageCard from "../components/ImageCard";
import Container from "../components/Container";

const Teaching = () => {
  return (
    <>
      <Header>
        <h2>Experiencia Docente</h2>
      </Header>
      <Container>
        <Section>
          <div className="cards_container">
            {UNIVERSITIES.map(({ imgPath, name, periods }, index) => (
              <ImageCard imgPath={imgPath} title={name} key={index}>
                <span>Periodos:</span>
                <ul>
                  {periods.map(({ period, teacherType }, i) => (
                    <li key={i}>
                      <p>
                        <b>{period}</b>
                        <br />
                        {teacherType}
                      </p>
                    </li>
                  ))}
                </ul>
              </ImageCard>
            ))}
          </div>
        </Section>
      </Container>
    </>
  );
};

export default Teaching;
