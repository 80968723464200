import React from "react";
import classes from "./ImageCard.module.css";

const ImageCard = ({ imgPath, title, children }) => {
  return (
    <div className={`${classes.image_card}`}>
      <img src={imgPath} alt="" />
      <div className={classes.body}>
        <b>{title}</b>
        {children}
      </div>
    </div>
  );
};

export default ImageCard;
