import React from "react";
import AnchorButton from "../components/AnchorButton";
import Button from "../components/Button";
import Container from "../components/Container";
import Header from "../components/Header";
import PageSection from "../components/PageSection";
import Section from "../components/Section";
import { ACADEMIC_PROFILES, COURSES, DEGREES, RECOGNITIONS } from "../data";
import classes from "./Home.module.css";

const Home = () => {
  const Presentation = () => (
    <Header>
      <div className={classes.content}>
        <Avatar imgPath={"images/big-avatar.jpg"} />
        <div className={classes.description}>
          <h2>Gladys N. Maquera Sosa</h2>
          <p>
            CEO de Grupo INNOP Perú, Presidenta Interina del Directorio de
            Electro Puno y Docente Universitario{" "}
          </p>
        </div>
      </div>
    </Header>
  );

  const Biography = () => (
    <Section className={` ${classes.biography}`} direction="row">
      <div className={classes.section__content}>
        <h2>Biografía</h2>
        <p>
          Gladys Maquera cuenta con gran experiencia trabajando en la región
          Puno y aportando a su desarrollo. Fue fundadora del Consejo Regional
          de Ciencia, Tecnología e Innovación de la Región Puno. Es docente en
          la <b>Universidad Peruana Unión</b> filial Juliaca. Actualmente cumple
          las responsabilidades de presidenta de directorio (e) de{" "}
          <b>Electro Puno S.A.A</b> y CEO de <b>Grupo INNOP</b> , empresa que
          creo para aportar a la formación de recursos humanos de la región.
          Además, ha desempeñado cargos administrativos en universidades
          nacionales como extranjeras.
        </p>
        <p>
          La Dra. Gladys Maquera se formó en renombradas universidades
          nacionales y extranjeras. Realizó su doctorado en Ingeniería de
          Producción en la Universidade Federal do Rio de Janeiro (UFRJ -
          Brasil) y tiene un pos doctorado en Ingeniería de Producción por la
          <b> Universidade Federal Fluminense</b> (UFF-Brasil). Así mismo es
          Magister en Ingeniería Eléctrica y de Computación por la{" "}
          <b>Universidade Estadual de Campinas</b> (UNICAMP - Brasil). Se graduó
          en Educación Matemática-Física por la <b>Universidad Peruana Unión</b>{" "}
          (UPeU - Perú). Ha publicado en revistas científicas y académicas, así
          como presentando distintos trabajos en eventos de ciencia, extensión y
          proyección universitaria. Además, es reconocida como{" "}
          <b>investigadora CONCYTEC</b>.
        </p>
        <p>
          La Dra. Gladys Maquera recibió la mentoría de grandes profesionales
          que marcaron su vida y aportaron a su formación profesional. Por esta
          razón busca aplicar y compartir los conocimientos adquiridos durante
          su formación y experiencia en proyectos de innovación. De esta forma
          espera lograr un impacto en su entorno que pueda beneficiar las
          personas más necesitadas.
        </p>
      </div>
      <img
        className={classes.biography_picture}
        src="images/biography-picture.jpg"
        alt=""
      />
    </Section>
  );

  const Education = () => (
    <Section direction="row">
      <div className={classes.subsection}>
        <h2>Grados Académicos</h2>
        <ul>
          {DEGREES.map(({ degree, university }, index) => (
            <li key={index}>
              <p>
                <b>{degree}</b>
                <br />
                {university.name} ({university.country})
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div className={`${classes.courses} ${classes.subsection}`}>
        <h2>Cursos</h2>
        <ul>
          {COURSES.map(({ name, year, institution }, index) => (
            <li key={index}>
              <p>
                <b>
                  {name} ({year})
                </b>
                <br />
                {institution}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </Section>
  );

  const Contact = () => (
    <div className={`${classes.contact} ${classes.section}`}>
      <h2>Ponte en contacto</h2>
      <div>
        <img src="images/contact.jpg" alt="" />
        <form action="">
          <div className={classes.form_control}>
            <label htmlFor="">Nombre y apellido</label>
            <input type="text" placeholder="Ingresa tu nombre y apellido" />
          </div>
          <div className={classes.form_row}>
            <div className={classes.form_control}>
              <label htmlFor="">Correo electrónico</label>
              <input type="email" placeholder="Ingresa tu correo electrónico" />
            </div>
            <div className={classes.form_control}>
              <label htmlFor="">Teléfono</label>
              <input type="tel" placeholder="Ingresa tu correo electrónico" />
            </div>
          </div>
          <div className={classes.form_control}>
            <label htmlFor="">Mensaje</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Ingresa tu mensaje"
            ></textarea>
          </div>
          <div>
            <Button>ENVIAR</Button>
          </div>
        </form>
      </div>
    </div>
  );

  const Recognitions = () => (
    <Section className={`${classes.recognitions}`}>
      <h2>Reconocimientos</h2>
      <div>
        <ul>
          {RECOGNITIONS.map(({ title, country, year }, index) => (
            <li key={index}>
              <p>
                <b>
                  {title} ({country}, {year})
                </b>
              </p>
            </li>
          ))}
        </ul>
        <img src="images/recognition.jpg" alt="" />
      </div>
    </Section>
  );

  const AcademicProfiles = () => (
    <Section className={`${classes.academic_profiles}`}>
      <h2>Perfiles Académicos</h2>
      <div>
        {ACADEMIC_PROFILES.map(({ title, description, url }, index) => (
          <AcademicProfile
            key={index}
            title={title}
            description={description}
            url={url}
          />
        ))}

        <img src="images/academic-profile.jpg" alt="" />
      </div>
    </Section>
  );

  return (
    <div>
      <Presentation />
      <Container>
        <Biography />
        <Education />
        <Recognitions />
        <AcademicProfiles />
        <PageSection
          imgPath={"images/innovation.jpg"}
          title={"Innovación e Investigación"}
          description={
            "La Dra. Gladys Maquera ha participado en proyectos multidisciplinarios y en pasantías internacionales. Ha aportado al conocimiento con publicaciones en prestigiosas revistas científicas. También ha asesorado múltiples trabajos de tesis."
          }
          pagePath={"/innovacion"}
        />
        <PageSection
          imgPath={"images/business.jpg"}
          title={"Experiencia Empresarial"}
          description={
            "Dra. Gladys Maquera es presidenta del directorio de Electro Puno S.A.A. y CEO de Grupo INNOP Perú E.I.R.L. Las responsabilidades confiadas la involucran en la dirección de las instituciones hacia el cumplimiento de los objetivos estratégicos. Es un desafío y oportunidad para aplicar la experiencia nacional e internacional adquirida a la realidad de la región."
          }
          pagePath={"/empresa"}
        />
        <PageSection
          imgPath={"images/teaching.jpg"}
          title={"Experiencia Docente"}
          description={
            "La Dra. Gladys Maquera ha desempeñado la labor docente en universidades privadas y publicas en el Perú como en el Brasil. Actualmente es docente ordinario-principal en la Universidad Peruana Unión filial Juliaca. Además de eso la docencia es una sus pasiones que le permite compartir su experiencia y conocimiento con los futuros lideres de la región."
          }
          pagePath={"/docencia"}
        />
      </Container>
    </div>
  );
};

const Avatar = ({ imgPath }) => {
  return (
    <div className={classes.avatar}>
      <img src={imgPath} alt="Gladys Maquera" />
    </div>
  );
};

const AcademicProfile = ({ title, description, url }) => {
  return (
    <div className={classes.academic_profile}>
      <h3>{title}</h3>
      <p>{description}</p>
      <AnchorButton href={url} target="_blank" rel="noopener noreferrer">
        VER PERFIL
      </AnchorButton>
    </div>
  );
};

export default Home;
